import { shorthash } from '@/common';
import type { TaxonomyPreview } from '@/types';

export function taxonomyHash(taxonomies: string[]) {
  const catalogueTaxonomies = taxonomies.filter((t) => t.startsWith('/catalogue/')) ?? [];
  const hasRegularTaxonomy = catalogueTaxonomies.some((t) => !t.startsWith('/catalogue/331'));
  const [mainTaxonomyCode] = (
    catalogueTaxonomies.filter((t) => !hasRegularTaxonomy || !t.startsWith('/catalogue/331')) ?? []
  ).reduce(
    ([r, l], t) => {
      const nl = t.split('/').length;
      return nl > l ? [t, nl] : [r, l];
    },
    ['', 0],
  );
  return shorthash(mainTaxonomyCode);
}

export function taxonomyDataPreviewWithAncestorsDirect(hash: string): TaxonomyPreview[] {
  if (hash == null || hash === '') return [];
  const store = global.__taxonomiesStates__;
  const f = (store.find((i) => i.data?.hash === hash)?.data ?? null) as TaxonomyPreview | null;
  if (f == null) return [];
  const pts = f.code.split('/').slice(2);
  if (pts.length === 0) return [];
  return pts
    .reduce<string[]>((a, cp) => [...a, a.length === 0 ? `/catalogue/${cp}` : `${a.slice(-1)}/${cp}`], [])
    .map((code) => store.find((x) => (x.data as TaxonomyPreview).code === code)?.data)
    .filter((x): x is TaxonomyPreview => x != null);
}
