import type { ContactData } from '@/graphql/__generated__/gql-client';
import { type AppAuthUser, type ProductDetail } from '@/types';
import type { MediaType, SupportedCurrency } from '@sprinx/knihovna-api-types';
import { PricesType } from '@sprinx/knihovna-api-types';
import type { TrpcRouterOutputs } from './trpc';

export type ProductDataForDataLayer = Pick<
  ProductDetail,
  'manufacturers' | 'media' | 'name' | 'price' | 'priceWithTax' | 'sku' | 'taxonomies'
> & {
  availability?: string | undefined;
  mainTaxonomies: (string | undefined)[];
  parameters?: { parameterCode: string; value: string }[];
  quantity?: number;
  total?: number;
  totalWithTax?: number;
  url?: string;
};

export enum PageTypeDataLayer {
  cart = 'cart',
  category = 'category',
  info = 'info',
  orderInfo = 'orderInfo',
  orderLoyalty = 'orderLoyalty',
  orderShipping = 'orderShipping',
  orderSummary = 'orderSummary',
  product = 'product',
  sellers = 'sellers',
  support = 'support',
  user = 'user',
  // TODO: what about TY page?
}

type GaEeDetailDataLayer = {
  ecommerce: {
    currencyCode: SupportedCurrency;
    detail: {
      products: {
        brand: string;
        category: string;
        id: number;
        name: string;
        price: number;
        quantity: number;
      }[];
    };
  };
  event: 'gaEeDetail';
};
type GaEeAddToCartDataLayer = {
  ecommerce: {
    addToCart: {
      products: {
        brand: string;
        category: string;
        id: number;
        name: string;
        price: number;
        quantity: number;
      }[];
    };
    currencyCode: SupportedCurrency;
  };
  event: 'gaEeAddToCart';
};
type GaEeremoveFromCartDataLayer = {
  ecommerce: {
    currencyCode: SupportedCurrency;
    removeFromCart: {
      products: {
        brand: string;
        category: string;
        id: number;
        name: string;
        price: number;
        quantity: number;
      }[];
    };
  };
  event: 'gaEeRemoveFromCart';
};

type GaEeImpressionsDataLayer = {
  ecommerce: {
    currencyCode: SupportedCurrency;
    impressions: {
      brand: string;
      category: string;
      id: number;
      name: string;
      price: number;
      quantity: number;
    }[];
  };
  event: 'gaEeImpressions';
};

type GaEePurchaseDataLayer = {
  ecommerce: {
    currencyCode: SupportedCurrency;
    purchase: {
      actionField: {
        action: 'purchase';
        affiliation: 'Vybavení pro úklid';
        id: string;
        revenue: number;
        shipping: number;
        tax: number;
      };
      products: {
        brand: string | undefined;
        category: string;
        id: number;
        name: string;
        price: number;
        quantity: number;
      }[];
    };
    user_data: {
      address: {
        city?: string;
        country: string;
        first_name: string;
        last_name: string;
        postal_code: string;
        region?: string;
        street?: string;
      };
      email: string;
      phone_number: string;
    };
  };
  event: 'gaEePurchase';
};

type SklikCategoryDataLayer = {
  event: 'sklikRetargetingCategory';
  sklikCategory: string;
};

type PageDataLayer = {
  page: {
    attributes: {
      author: 'Vybavení pro úklid';
      publishedDate: string; // YYYY-MM-DD
      updatedDate: string; // YYYY-MM-DD
    };
    categorization: { main: string[]; type: PageTypeDataLayer };
    identification: { statusCode: 200; url: string };
  };
};

type ProductsDataLayer = {
  products: {
    attributes: {
      availability: string | null;
      freeShipping: 'Ano' | 'Ne';
      isAction: 'Ano' | 'Ne';
      isEcological: 'Ano' | 'Ne';
      isNatural: 'Ano' | 'Ne';
      isNew: 'Ano' | 'Ne';
      isRecommended: 'Ano' | 'Ne';
      isSale: 'Ano' | 'Ne';
      package: string | null;
    };
    categorization: { main: string[]; manufacturer: string };
    identification: {
      id: number;
      image: string;
      name: string;
      url: string;
    };
    // pagio: { place: 'page-content'; position: 1; view: 'eshop::filter.layout.index' };
    value: {
      amount: number;
      currency: string;
      totalWithoutVat: number;
      totalWithVat: number;
      unitWithoutVat: number;
      unitWithVat: number;
    };
  }[];
};

type PriceVatViewDataLayer = {
  event: 'priceVatView';
  priceVatViewData: {
    price: 'withVat' | 'withoutVat';
  };
};

type UserDataLayer = {
  user: {
    attributes?: {
      currency: SupportedCurrency;
      transactionCount: number;
      transactionTotal: number;
    };
    identification: {
      id: string;
      loginStatus: 'Nepřihlášený' | 'Přihlášený';
    };
    pagio: {
      customerExtId: string | null;
      customerGroupExtId: null;
      customerGroupId: null;
      customerId: number | null;
      userExtId: string | null;
    };
  };
};

type DataLayer =
  | GaEeDetailDataLayer
  | GaEeAddToCartDataLayer
  | GaEeremoveFromCartDataLayer
  | GaEeImpressionsDataLayer
  | GaEePurchaseDataLayer
  | PageDataLayer
  | ProductsDataLayer
  | PriceVatViewDataLayer
  | UserDataLayer
  | SklikCategoryDataLayer;

type SambaParams = {
  content?: string[];
  count?: number;
};

type SambaResult = {
  recommendation: {
    brand: string;
    formattedPrice: string;
    id: string;
    image: string;
    interactionParams: Record<string, any>;
    name: string;
    originalUrl: string;
    price: number;
    url: string;
  }[];
};

type SambaCallback = (err: any, result: SambaResult) => void;

type SambaPersonaliserCall = (params: SambaParams, callback: SambaCallback) => void;

// `en-CA` is used because this locale returns YYYY-MM-DD format
const dateFormat = (v: string | number | Date | null | undefined, defaultValue = ''): string =>
  v != null ? new Intl.DateTimeFormat('en-CA').format(new Date(v)) : defaultValue;

export function addPriceVatViewDataLayer(pricesType: PricesType, user?: AppAuthUser) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  const price = pricesType === PricesType.B2C ? 'withVat' : 'withoutVat';
  dataLayer.push({
    event: 'priceVatView',
    priceVatViewData: {
      price,
    },
  } as PriceVatViewDataLayer);
  SambaNotifyIfLoggedIn(user);
}

export function addProductsDataLayer(
  products: ProductDataForDataLayer[],
  currency: SupportedCurrency,
  user?: AppAuthUser | undefined,
  onOrderPage?: boolean,
) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    products: products.map((p) => productMapper(p, currency)),
  } as ProductsDataLayer);
  SambaNotifyCart(products, onOrderPage);
  SambaNotifyIfLoggedIn(user);
}

function productMapper(product: ProductDataForDataLayer, currency: SupportedCurrency) {
  const quantity = product.quantity ?? 1;
  return {
    attributes: {
      availability: product.availability ?? null,
      freeShipping: 'Ne', // TODO: what is this?
      isAction: product.taxonomies?.includes('/adorements/action') ? 'Ano' : 'Ne',
      isEcological: 'Ne', // TODO: what is this?
      isNatural: 'Ne', // TODO: what is this?
      isNew: product.taxonomies?.includes('/adorements/new') ? 'Ano' : 'Ne',
      isRecommended: product.taxonomies?.includes('/adorements/recommend') ? 'Ano' : 'Ne',
      isSale: product.taxonomies?.includes('/adorements/discount') ? 'Ano' : 'Ne',
      package:
        product.parameters?.find((param) => param.parameterCode === '/allservices/mnozstvi_v_kartonu')?.value ?? null,
    },
    categorization: {
      main: [product.mainTaxonomies[0]], // ? bere se jen prvni kategorie?
      manufacturer: product.manufacturers[0]?.name ?? '',
    },
    identification: {
      id: Number(product.parameters?.find((param) => param.parameterCode === '/allservices/idfeed')?.value ?? 0),
      image: product.media?.[0]?.src ?? '',
      name: product.name,
      url: product.url,
    },
    value: {
      amount: quantity,
      currency: currency,
      totalWithoutVat: product.total ?? product.price * quantity,
      totalWithVat: product.totalWithTax ?? product.priceWithTax * quantity,
      unitWithoutVat: product.price,
      unitWithVat: product.priceWithTax,
    },
  };
}

export function addEEDetailDataLayer(
  product: ProductDataForDataLayer,
  currency: SupportedCurrency,
  user?: AppAuthUser,
) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      detail: {
        products: [eEProductMapper(product)],
      },
    },
    event: 'gaEeDetail',
  } as GaEeDetailDataLayer);
  SambaNotifyProduct(product);
  SambaNotifyIfLoggedIn(user);
}

export function addEEAddToCartDataLayer(products: ProductDataForDataLayer[], currency: SupportedCurrency) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: {
      addToCart: {
        products: products.map(eEProductMapper),
      },
      currencyCode: currency,
      value: products.reduce((accumulator, product) => {
        if (!product.quantity) {
          return accumulator + 0;
        }
        return accumulator + Number((product.price * product.quantity).toFixed(2));
      }, 0),
    },
    event: 'gaEeAddToCart',
  } as GaEeAddToCartDataLayer);
}

export function removeEERemoveFromCartDataLayer(products: ProductDataForDataLayer[], currency: SupportedCurrency) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      removeFromCart: {
        products: products.map(eEProductMapper),
      },
      value: products.reduce((accumulator, product) => {
        if (!product.quantity) {
          return accumulator + 0;
        }
        return accumulator + Number((product.price * product.quantity).toFixed(2));
      }, 0),
    },
    event: 'gaEeRemoveFromCart',
  } as GaEeremoveFromCartDataLayer);
}

export function addEEImpressionsDataLayer(products: ProductDataForDataLayer[], currency: SupportedCurrency) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      impressions: products.map(eEProductMapper),
    },
    event: 'gaEeImpressions',
  } as GaEeImpressionsDataLayer);
}

function eEProductMapper(product: ProductDataForDataLayer) {
  return {
    brand: product.manufacturers[0]?.name,
    category: product.mainTaxonomies.join('/'),
    id: Number(product.parameters?.find((param) => param.parameterCode === '/allservices/idfeed')?.value ?? 0),
    name: product.name,
    price: product.price,
    quantity: product.quantity ?? 1,
  };
}

export function addEEPurchaseDataLayer(
  products: ProductDataForDataLayer[],
  order: {
    invoiceInfo: ContactData;
    number: string;
    shipping: number;
    total: number;
    totalVat: number;
  },
  currency: SupportedCurrency,
  user?: AppAuthUser | undefined,
) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];

  const nameSplit = order.invoiceInfo.personName?.split(' ') ?? null;
  const first_name = nameSplit?.[0].trim() ?? null;
  const last_name = nameSplit?.slice(1).join(' ').trim() ?? null;
  const email = order.invoiceInfo.emailAddress?.trim().toLocaleLowerCase();
  const phoneNumber = order.invoiceInfo.phoneNumber?.trim();
  const postal_code = order.invoiceInfo.postalCode?.trim();
  const country = order.invoiceInfo.country?.trim();

  dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          action: 'purchase',
          affiliation: 'Vybavení pro úklid',
          id: order.number,
          revenue: order.total,
          shipping: order.shipping,
          tax: order.totalVat - order.total,
        },
        products: products.map(eEProductMapper),
      },
      ...(email &&
        phoneNumber &&
        first_name &&
        last_name &&
        postal_code &&
        country && {
          user_data: {
            address: {
              city: order.invoiceInfo.city,
              country,
              first_name: first_name,
              last_name: last_name,
              postal_code,
              street: order.invoiceInfo.street,
            },
            email,
            phone_number: phoneNumber,
          },
        }),
    },

    event: 'gaEePurchase',
  } as GaEePurchaseDataLayer);
  SambaNotifyOrder(products);
  SambaNotifyIfLoggedIn(user);

  (async () => {
    await fetch('/logGAPurchase', {
      body: JSON.stringify({ orderNumber: order.number, timestamp: new Date().toISOString() }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  })();
}

export function addUserDataLayer(user: AppAuthUser | undefined, ordersCount?: number) {
  const dataLayer: DataLayer[] = (window as any).dataLayer || [];
  dataLayer.push({
    user: {
      ...(user != null && {
        attributes: {
          currency: user.settings?.currency,
          transactionCount: ordersCount ?? 0,
          transactionTotal: 0,
        },
      }),
      identification: {
        id: user?.settings?.customer ?? '',
        loginStatus: user == null ? 'Nepřihlášený' : 'Přihlášený',
      },
      pagio: {
        customerExtId: null,
        customerGroupExtId: null,
        customerGroupId: null,
        customerId: null,
        userExtId: null,
      },
    },
  } as UserDataLayer);
  SambaNotifyIfLoggedIn(user);
}

export function addPageDataLayer(
  pageType: PageTypeDataLayer,
  url: string,
  categories: string[],
  publishedDate?: string | number | Date | null,
  updatedDate?: string | number | Date | null,
  user?: AppAuthUser | undefined,
) {
  const dataLayer = getDl();
  dataLayer.push({
    page: {
      attributes: {
        author: 'Vybavení pro úklid',
        publishedDate: dateFormat(publishedDate),
        updatedDate: dateFormat(updatedDate),
      },
      categorization: { main: categories, type: pageType },
      identification: { statusCode: 200, url },
    },
  } as PageDataLayer);

  if (pageType === PageTypeDataLayer.category) {
    dataLayer.push({
      event: 'sklikRetargetingCategory',
      sklikCategory: categories.join(' | '),
    } as SklikCategoryDataLayer);
  }

  SambaNotifyIfLoggedIn(user);
}

export function subjectEnrichedProductMapper(
  cartSubject: TrpcRouterOutputs['cart']['subjects'][number],
  productDetailUrl: (params: { name: string; sku: string }) => string,
): ProductDataForDataLayer {
  return {
    availability: (cartSubject.product?.stockAvailableQuantity as any)?.placeholder.text ?? '',
    mainTaxonomies: (cartSubject.product as any)?.mainTaxonomyNames ?? [],
    manufacturers: cartSubject.product?.manufacturers ?? [],
    media: cartSubject.product?.media?.map(({ src, type }) => ({ src, type: type as MediaType })) ?? [],
    name: cartSubject.text,
    parameters: cartSubject.product?.parameters?.map((p) => ({ parameterCode: p.code, value: p.value })) ?? [],
    price: cartSubject.price.amount,
    priceWithTax: cartSubject.price.amountWithTax,
    quantity: cartSubject.quantity,
    sku: cartSubject.productSku,
    taxonomies: cartSubject.product?.taxonomies ?? [],
    total: cartSubject.subjectTotal.amount,
    totalWithTax: cartSubject.subjectTotal.amountWithTax,
    url: window.location.origin + productDetailUrl({ name: cartSubject.text, sku: cartSubject.productSku }),
  };
}

function getDl(): DataLayer[] {
  return (window as any).dataLayer || ([] as DataLayer[]);
}
function getSamba() {
  return (window as any).diffAnalytics;
}
function SambaNotifyIfLoggedIn(auth: any | undefined) {
  if (auth != null && auth.settings != null && auth.settings.customer != null)
    getSamba()?.customerLoggedIn(auth.settings.customer);
}
function SambaNotifyOrder(products: ProductDataForDataLayer[]) {
  const reduced = products.reduce((acc: any, v) => {
    if (acc[v.sku] == null) {
      acc[v.sku] = (v?.quantity ?? 0) * (v?.priceWithTax ?? 0);
    } else {
      acc[v.sku] += (v?.quantity ?? 0) * (v?.priceWithTax ?? 0);
    }
    return acc;
  }, {});
  getSamba()?.order({ content: Object.entries(reduced).map((a) => ({ price: a[1], productId: a[0] })) });
}

function SambaNotifyCart(products: ProductDataForDataLayer[], onOrderPage?: boolean) {
  const reduced = products.reduce((acc: any, v) => {
    if (acc[v.sku] == null) {
      acc[v.sku] = v?.quantity ?? 0;
    } else {
      acc[v.sku] += v?.quantity ?? 0;
    }
    return acc;
  }, {});
  getSamba()?.cartInteraction({
    content: Object.entries(reduced).map((a) => ({ price: a[1], productId: a[0] })),
    onOrderPage: onOrderPage ?? false,
  });
}

function SambaNotifyProduct(product: ProductDataForDataLayer) {
  getSamba()?.productId(product.sku);
}

export function SambaPersonaliser(target: 'cart' | 'cross-sell' | 'homepage' | 'similar'): SambaPersonaliserCall {
  const campaignName = undefined;
  const personaliser = getSamba()?.personaliser(campaignName);
  return {
    cart: (params: SambaParams, callback: SambaCallback) => personaliser?.basketComplements(params, callback),
    'cross-sell': (params: SambaParams, callback: SambaCallback) =>
      personaliser?.personalisedComplements(params, callback),
    homepage: (params: SambaParams, callback: SambaCallback) => personaliser?.personalisedHomepage(params, callback),
    similar: (params: SambaParams, callback: SambaCallback) => personaliser?.personalisedSubstitutes(params, callback),
  }[target];
}

export function SambaProductInteraction(interactionParams: SambaResult['recommendation'][0]['interactionParams']) {
  return getSamba()?.productInteraction(interactionParams);
}
